<template>
  <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">

    <v-card v-show="!dialogs.add" class=" elevation-1 rounded-lg overflow-hidden">
      <v-card-title class="d-flex align-center py-3">
        <v-icon left color="primary">mdi-account-group-outline</v-icon>
        <span class="text-h6">Usuarios</span>
        <v-spacer></v-spacer>

        <!-- Add User Button -->
        <v-btn @click="showAdd" color="primary" class="rounded-lg"
          v-if="$store.state.Auth.token.claims.type && ['superuser', 'admin', 'entrenador'].includes($store.state.Auth.token.claims.type)">
          <v-icon left>mdi-plus</v-icon>
          Agregar Usuario
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-tabs background-color="transparent" color="primary" slider-color="primary">
        <v-tab @click="getUsers" class="text-none">
          <v-icon left small>mdi-account-check</v-icon>
          Habilitados
        </v-tab>
        <v-tab @click="loadDisabledUsers" class="text-none">
          <v-icon left small>mdi-account-off</v-icon>
          Deshabilitados
        </v-tab>
      </v-tabs>
      <v-divider />



      <v-card-text>
        <Table :data="users" :headers="headers" :loading="loading" :icon="'mdi-account'" :title="'fullname'"
          :subtitle="'type'" :subtitleIcon="'mdi-account-box'" :acceptCompanies="true" :companies="companies"
          @save="showSave" @add="showAdd" @add2="showAdd2" @delete="showDelete" @getCompany="getUsers"
          @search="searchUser"></Table>
      </v-card-text>
    </v-card>


    <v-card v-show="dialogs.add" class="elevation-1 rounded-lg overflow-hidden">

      <v-toolbar flat class="pl-1">
        <v-icon @click="dialogs.add = false" left>mdi-chevron-left</v-icon>
        <v-avatar size="45" class="mr-2">
          <UserImage :user="user" v-if="user && dialogs.add && dialogs.save && user.id" small />
        </v-avatar>
        {{ user.displayName || 'Nuevo Usuario' }}
      </v-toolbar>





      <!--   <v-btn @click="sendMail" v-if="$store.state.Auth.token.claims.type == 'superuser'">
        mandar mail
      </v-btn> -->
      <v-tabs v-model="selectedTab" show-arrows background-color="transparent" color="primary" slider-color="primary">
        <v-tab :key="'Cuenta'">
          <v-icon left>
            mdi-account
          </v-icon>
          Cuenta
        </v-tab>



        <v-tab :disabled="dialogs.add && !dialogs.save" :key="'Pagos'">

          <v-icon left>
            mdi-cash
          </v-icon>
          Pagos
        </v-tab>

        <v-tab :disabled="dialogs.add && !dialogs.save" :key="'Preferencias'">
          <v-icon left>
            mdi-dumbbell
          </v-icon>
          Entrenamiento
        </v-tab>


        <v-tab :disabled="dialogs.add && !dialogs.save" :key="'Evaluaciones'">
          <v-icon left>
            mdi-pencil-plus
          </v-icon>
          Evaluaciones
        </v-tab>

        <v-tab
          :disabled="dialogs.add && !dialogs.save && user && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"
          :key="'Asistencias'">
          <v-icon left>
            mdi-calendar-check
          </v-icon>

          Asistencias
        </v-tab>


        <v-tab
          :disabled="dialogs.add && !dialogs.save && user && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"
          :key="'Licencias'">
          <v-icon left>
            mdi-tent
          </v-icon>

          Licencias
        </v-tab>


        <v-tab-item>


          <v-divider />
          <v-form ref="add-form" lazy-validation>
            <v-subheader>
              Informacion de Usuario
            </v-subheader>
            <v-row class="px-2">
              <v-col cols="12">
                <v-row class="px-2">


                  <v-col cols="12" md="6">
                    <v-text-field filled dense rounded v-model.trim="user.displayName" :rules="[rules.required]"
                      class="rounded-lg" hide-details prepend-inner-icon="mdi-account"
                      :label="$i18n.t('USER.nameLabel')"></v-text-field>


                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select dense v-if="userTypes.length > 1" class="rounded-lg" v-model="user.type" filled
                      hide-details :rules="[rules.required]" rounded :items="userTypes"
                      :label="$i18n.t('USER.typeLabel')" prepend-inner-icon="mdi-account-box" item-text="text"
                      item-value="value"></v-select>

                  </v-col>


                  <v-col cols="12" md="6">
                    <v-text-field filled dense rounded v-model.trim="user.civilId" :rules="[rules.required]"
                      class="rounded-lg" hide-details prepend-inner-icon="mdi-identifier" label="Id / Documento"
                      :readonly="(dialogs.save == true && !dialogs.add == false)"
                      :append-icon="(dialogs.save == true && !dialogs.add == false) && user && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') ? 'mdi-pencil' : ''"
                      @click:append="editCivilId = true; newCivilId = null"></v-text-field>

                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field filled dense rounded v-model.trim="user.email" class="rounded-lg" hide-details
                      :rules="[rules.required, rules.email]" :readonly="(dialogs.save == true && !dialogs.add == false)"
                      prepend-inner-icon="mdi-email" :label="$i18n.t('USER.mailLabel')"
                      :append-icon="(dialogs.save == true && !dialogs.add == false) && user.type != 'superuser' ? 'mdi-pencil' : ''"
                      @click:append="editEmail = true; newEmail = null"></v-text-field>


                  </v-col>



                  <v-col cols="12" md="6">
                    <vue-tel-input class="rounded-lg" :preferred-countries="['UY']" ref="telephoneNumber"
                      :placeholder="$i18n.t('USER.phoneLabel')" @input="onInputPhone"
                      v-model="user.phoneNumber"></vue-tel-input>
                    <label style="color:red" v-show="errorPhone">{{ $i18n.t('USER.INVALIDPHONE') }}</label>


                  </v-col>

                  <v-col cols="12" md="6">

                    <v-text-field class="rounded-lg" v-model="user.country" filled dense rounded label="País"
                      hide-details prepend-inner-icon="mdi-map-marker"></v-text-field>


                  </v-col>


                  <v-col cols="12" md="6">
                    <v-text-field class="rounded-lg" v-model="user.address" filled dense rounded label="Dirección"
                      hide-details prepend-inner-icon="mdi-map-marker"></v-text-field>

                  </v-col>

                  <v-col cols="12" md="6">
                    <v-menu width="100%" ref="menuEval" v-model="menuEval" :close-on-content-click="false"
                      :return-value.sync="user.birthDate" transition="scale-transition" offset-y max-width="300"
                      min-width="300">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled rounded v-model="user.birthDate" label="Fecha de Nacimiento"
                          class="rounded-lg" hide-details prepend-inner-icon="mdi-calendar" readonly dense
                          v-bind="attrs" v-on="on" required></v-text-field>
                      </template>
                      <v-date-picker v-model="user.birthDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuEval = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menuEval.save(user.birthDate)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>

                  </v-col>

                  <v-col cols="12" md="6">


                    <v-text-field v-model="user.medicalService" filled dense rounded label="Servicio Medico"
                      hide-details="" class="rounded-lg" prepend-inner-icon="mdi-truck-plus"></v-text-field>

                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled dense rounded class="rounded-lg" v-model="user.observation" hide-details=""
                      label="Observaciones" prepend-inner-icon="mdi-comment"></v-text-field>

                  </v-col>

                  <v-col cols="12" md="6">
                    <v-select v-model="user.gender" :items="genders" label="Género" filled dense rounded
                      class="rounded-lg" prepend-inner-icon="mdi-gender-male-female"></v-select>
                  </v-col>




                </v-row>
              </v-col>

              <!--    <v-col cols="12" md="4" >
                                
                  <h4 >Plan (dias de entrenamiento semanales)</h4>
                  <v-radio-group v-model="user.plan"  row dense >
                    <v-radio label="1" :value="1"></v-radio>
                    <v-radio label="2" :value="2"></v-radio>
                    <v-radio label="3" :value="3"></v-radio>
                    <v-radio label="4" :value="4"></v-radio>
                    <v-radio label="5" :value="5"></v-radio>
                    <v-radio label="6" :value="6"></v-radio>
                    <v-radio label="7" :value="7"></v-radio>
                    <v-radio label="Libre" :value="0"></v-radio>
                  </v-radio-group> 

                       <v-alert text  type="info" v-if="($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') && dialogs.save == false && dialogs.add == true">
                           Solo para usuarios que ya manejen un vencimiento de suscripción.
                         <v-menu width="100%"    
                              v-if="($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"
                ref="menuEndOfSubscription"
                v-model="menuEndOfSubscription"
                :close-on-content-click="false"
                :return-value.sync="user.endOfSubscription"
                transition="scale-transition"
                offset-y
                max-width="300" min-width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field filled rounded class="mt-4 rounded-lg" 
                    v-model="user.endOfSubscription"
                    label="Fecha vencimiento"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    hide-details=""
                    :light="!$store.state.isDark"


                  ></v-text-field>
                </template>
                <v-date-picker v-model="user.endOfSubscription" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuEndOfSubscription = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.menuEndOfSubscription.save(user.endOfSubscription)">OK</v-btn>
                </v-date-picker>
              </v-menu>  
                </v-alert>
               



                    </v-col> -->
            </v-row>

          </v-form>


        </v-tab-item>

        <v-tab-item>
          <v-divider />

          <UserPayments
            v-if="user && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)"
            v-show="selectedTab == 1" :userOBJ="user" :user="user.id" :company="companySelected" @payment="paymentDone"
            :key="user.id" />
          <template v-else>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-alert text type="info" elevation="2" icon="mdi-information">
                    <span class="font-weight-bold">Información</span>
                    <br>
                    <span>Recuerde solo los usuarios socios realizan pagos.</span>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-tab-item>


        <v-tab-item>
          <v-divider />

          <Preferences :isCoachView="true" :user='user' v-if="user" :key="user.id + 'Preferences'" />
        </v-tab-item>



        <v-tab-item>
          <v-divider />

          <v-card max-width="700" class="mx-auto glass my-4">

            <v-card-title>
              Evaluaciones
              <v-spacer></v-spacer>

              <v-btn @click="dialogs.evaluationDate = true" color="success">
                <v-icon left>mdi-calendar-plus</v-icon>
                Agendar
              </v-btn>

            </v-card-title>
            <v-divider />
            <v-card-text>
              <template v-if="evaluations.length">
                <v-card class="mb-2" outlined v-model="selectedEvaluationIndex"
                  v-for="(evaluation, index) in evaluations" :key="index">
                  <v-card-title>
                    <v-chip small class="mr-2">{{ returnDateFormated(evaluation.date) }}</v-chip>

                    <v-chip :color="evaluation && evaluation.evaluatedBy ? 'success' : 'error'" outlined class="mr-4">
                      {{ evaluation && evaluation.evaluatedBy ? "Completada" : "Pendiente" }}
                    </v-chip>





                  </v-card-title>
                  <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn small elevation="0" @click="openDialog(index)">
                      <v-icon left>
                        mdi-eye
                      </v-icon>
                      Ver/editar
                    </v-btn>
                    <v-btn small elevation="0" @click="editEvaluationDate(evaluation)">
                      <v-icon left>
                        mdi-file-edit-outline
                      </v-icon>
                      Editar cita
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>

              <v-alert v-else type="info" icon="mdi-information" text>
                <span class="font-weight-bold">Información</span><br>

                No hay evaluaciones registradas<br>


              </v-alert>



              <v-dialog v-model="dialogs.evaluationDate" max-width="300">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Agendar evaluación</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogs.evaluationDate = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="evaluationDateForm">
                      <v-row>
                        <v-col cols="12">
                          <v-menu width="100%" ref="menu" v-model="evaluationDateObj.menu"
                            :close-on-content-click="false" :return-value.sync="evaluationDateObj.date"
                            transition="scale-transition" offset-y max-width="300" min-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field filled v-model="evaluationDateObj.date" label="Fecha"
                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" hide-details="" v-on="on"
                                required :rules="[rules.required]"></v-text-field>
                            </template>
                            <v-date-picker v-model="evaluationDateObj.date" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="evaluationDateObj.menu = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.menu.save(evaluationDateObj.date)">OK</v-btn>
                            </v-date-picker>
                          </v-menu>

                          <!--Time picker-->
                          <v-menu width="100%" ref="menuTime" v-model="evaluationDateObj.menuTime"
                            :close-on-content-click="false" :return-value.sync="evaluationDateObj.time"
                            transition="scale-transition" offset-y max-width="300" min-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field filled v-model="evaluationDateObj.time" label="Hora"
                                prepend-inner-icon="mdi-clock" readonly v-bind="attrs" v-on="on" required
                                :rules="[rules.required]" hide-details=""></v-text-field>
                            </template>
                            <v-time-picker v-model="evaluationDateObj.time" full-width>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="evaluationDateObj.menuTime = false">Cancel</v-btn>
                              <v-btn text color="primary"
                                @click="$refs.menuTime.save(evaluationDateObj.time)">OK</v-btn>
                            </v-time-picker>
                          </v-menu>

                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>

                    <v-btn text color="error" small @click="deleteEvaluationDate" v-if="evaluationDateObj.doc_id">
                      <v-icon left>
                        mdi-delete
                      </v-icon>
                      Eliminar
                    </v-btn>


                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveEvaluationDate" :loading="loading">
                      <v-icon left>
                        mdi-calendar-plus
                      </v-icon>

                      {{ evaluationDateObj.doc_id ? 'Editar' : 'Agendar' }}
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </v-dialog>

              <!-- Evaluation dialog -->
              <v-dialog v-model="dialogs.evaluation" max-width="500px" persistent>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Evaluación
                    </span> <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="evaluationForm">

                      <h3 class="text-h6 mb-4">Datos Base</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.altura" label="Altura" hint="Opcional" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.peso" label="Peso" hint="Opcional" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>




                      <v-text-field v-model="evaluation.nivelActividadFisica" label="Nivel de actividad física" filled
                        rounded></v-text-field>
                      <v-textarea v-model="evaluation.objetivosCortoPlazo" label="Objetivos a corto plazo" filled
                        rounded></v-textarea>
                      <v-textarea v-model="evaluation.objetivosLargoPlazo" label="Objetivos a largo plazo" filled
                        rounded></v-textarea>

                      <h3 class="text-h6 mb-4">Evaluación de Movimiento - GLOBAL</h3>
                      <v-text-field v-model="evaluation.extension" label="Extensión" filled rounded></v-text-field>
                      <v-text-field v-model="evaluation.flexion" label="Flexión" filled rounded></v-text-field>
                      <v-text-field v-model="evaluation.rotacion" label="Rotacion" filled rounded></v-text-field>
                      <v-text-field v-model="evaluation.izquierdaGlobal" label="Izquierda" filled
                        rounded></v-text-field>
                      <v-text-field v-model="evaluation.derechaGlobal" label="Derecha" filled rounded></v-text-field>
                      <v-text-field v-model="evaluation.sentadillaOH" label="Sentadilla OH" filled
                        rounded></v-text-field>
                      <v-textarea v-model="evaluation.observacionesGlobal" label="Observaciones" filled
                        rounded></v-textarea>

                      <h3 class="text-h6 mb-4">Analíticas</h3>
                      <v-text-field v-model="evaluation.respiracionDiafragmatica" label="Respiración diafragmática"
                        filled rounded></v-text-field>

                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.capacidadTotal" label="Capacidad total" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.capacidadResidual" label="Capacidad residual" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.scSuperior" label="SC superior" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.scInferior" label="SC inferior" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>



                      <v-text-field v-model="evaluation.sobrepronacion" label="Sobrepronacion" filled
                        rounded></v-text-field>


                      <h3 class="mb-2 text-center">Movilidad cadera pierna recta</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.movilidadCaderaPiernaRectaIzquierda" label="Izquierda"
                            filled rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.movilidadCaderaPiernaRectaDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>

                      <h3 class="mb-2 text-center">Test Thomas</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.testThomasIzquierda" label="Izquierda" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.testThomasDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>

                      <h3 class="mb-2 text-center">Activación glúteo</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.activacionGluteoIzquierda" label="Izquierda" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.activacionGluteoDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>

                      <h3 class="mb-2 text-center">Rotacion interna</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.rotacionInternaIzquierda" label="Izquierda" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.rotacionInternaDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>

                      <h3 class="mb-2 text-center">Hombros</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.hombrosIzquierda" label="Izquierda" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.hombrosDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>

                      <h3 class="mb-2 text-center">Rotacion dorsal</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.rotacionDorsalIzquierda" label="Izquierda" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.rotacionDorsalDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>

                      <h3 class="mb-2 text-center">Dorsiflexion tobillo</h3>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.dorsiflexionTobilloIzquierda" label="Izquierda" filled
                            rounded></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="evaluation.dorsiflexionTobilloDerecha" label="Derecha" filled
                            rounded></v-text-field>
                        </v-col>
                      </v-row>


                      <v-textarea v-model="evaluation.resumen" label="Resumen" filled rounded></v-textarea>


                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" :disabled="loading" text @click="closeDialog">Volver</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :loading="loading" @click="submitEvaluation">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-tab-item>


        <v-tab-item> <v-divider />

          <v-card class="glass mx-auto my-4" max-width="900">
            <v-card-text>
              <v-menu width="100%" ref="menu" v-model="menu" :close-on-content-click="false"
                transition="scale-transition" offset-y max-width="300" min-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dates" label="Selecciona un rango de fechas" hide-details filled rounded
                    class="rounded-lg" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker range v-model="dates" scrollable hide-details :min="dates.length > 0 ? dates[0] : null">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary darken-3" @click="menu = false; dates = []">Cancelar</v-btn>
                  <v-btn text color="primary" @click="menu = false; searchWorkouts()">OK</v-btn>
                </v-date-picker>
              </v-menu>


              <v-subheader class="my-4 text-h6">
                Total de asistencias: {{ totalWorkouts }}
              </v-subheader>

              <v-row>
                <v-col cols="12" md="6">
                  <VueApexCharts :series="series" type="line" :options="chartOptions"></VueApexCharts>
                </v-col>
                <v-col cols="12" md="6">
                  <VueApexCharts type="bar" :options="{
                    plotOptions: {
                      bar: {
                        distributed: true
                      }

                    },
                    legend: {
                      show: false
                    }, theme: {
                      mode: 'dark',
                    },
                    title: {
                      align: 'center',
                      style: {
                        fontSize: '16px',
                      }
                    },
                  }" :series="weekdaySeries"></VueApexCharts>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-divider />

          <LicsenceManagerVue :user="user" v-if="user && selectedTab == 5"
            @updateEndOfSubscription="updateEndOfSubscription" />
        </v-tab-item>
      </v-tabs>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error"
          v-if="dialogs.add && dialogs.save && user && user.type != 'superuser' && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') && selectedTab == 0 && user.disabled == false"
          @click="disableDialog = true">
          <v-icon left>mdi-account-off</v-icon>
          Baja
        </v-btn>

        <v-btn color="primary"
          v-if="dialogs.add && dialogs.save && user && user.type != 'superuser' && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') && selectedTab == 0 && user.disabled == true"
          @click="enableDialog = true">
          Habilitar
        </v-btn>

        <v-btn
          v-if="dialogs.add && dialogs.save && user && user.type != 'superuser' && ($store.state.Auth.token.claims.type == 'superuser')"
          depressed :loading="buttonLoader" color="red" dark x-small text @click.prevent="showDelete(user)">
          Eliminar cuenta
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text v-if="user" @click="dialogs.add = false; dialogs.save = false; selectedTab = 0">
          Volver
        </v-btn>

        <template v-if="selectedTab == 0">

          <v-btn v-if="dialogs.add && !dialogs.save" depressed :loading="buttonLoader" color="green" dark
            @click.prevent="add">
            {{
              $i18n.t("GENERAL.add")
            }}
          </v-btn>

          <template v-else>
            <v-btn depressed :loading="buttonLoader" color="green" dark @click.prevent="save">
              {{
                $i18n.t("GENERAL.save") }}</v-btn>

          </template>

        </template>

      </v-card-actions>
    </v-card>

    <v-dialog v-model="disableDialog" v-show="user && user.id" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Baja de usuario</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="disableDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-alert type="error" elevation="2" icon="mdi-alert">
                <span class="font-weight-bold">Atención</span><br>
                ¿Está seguro que desea dar de baja a este usuario?
                <strong>{{ user.displayName }}</strong>
              </v-alert>
              <v-text-field v-model="disableReason" filled rounded label="Motivo"
                prepend-inner-icon="mdi-message"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="disableDialog = false" :disabled="loading">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="disableUser" :loading="loading">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>

    <v-dialog v-model="enableDialog" max-width="500px">
      <v-card>

        <v-card-title>
          <span class="text-h5">Habilitar usuario</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="enableDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-alert type="info" elevation="2" icon="mdi-information">
                <span class="font-weight-bold">Atención</span><br>
                ¿Está seguro que desea habilitar a este usuario?
                <strong>{{ user.displayName }}</strong>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="enableDialog = false" :disabled="loading">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="enableUser" :loading="loading">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Delete v-model="this.dialogs.delete" @remove="remove" @close="closeDelete" :loading="buttonLoader"></Delete>

    <v-dialog v-model="editCivilId" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar ID/DOCUMENTO</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="editCivilId = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-alert type="info" elevation="2" icon="mdi-information">
                <span class="font-weight-bold">Atención</span><br>
                ¿Está seguro que desea editar el identificador de este usuario?
                <strong>{{ user.displayName }}</strong>
              </v-alert>
              <v-text-field v-model="newCivilId" filled rounded label="ID/documento nuevo"
                prepend-inner-icon="mdi-identifier"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="editCivilId = false" :disabled="loading">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="newCivilId == null" @click="saveCivilId()" :loading="loading">
            <v-icon left>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog v-model="editEmail" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar Email</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="editEmail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-alert type="info" elevation="2" icon="mdi-information">
                <span class="font-weight-bold">Atención</span><br>
                ¿Está seguro que desea editar el email de este usuario?
                <strong>{{ user.displayName }}</strong>
              </v-alert>
              <v-text-field v-model="newEmail" filled rounded label="Email nuevo"
                prepend-inner-icon="mdi-email"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="primary" @click="editEmail = false" :disabled="loading">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="newEmail == null" @click="saveEmail()" :loading="loading">
            <v-icon left>mdi-check</v-icon>
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>

import { getFirestore, collection, query, where, orderBy, getDocs, addDoc, updateDoc, doc, Timestamp, serverTimestamp, deleteDoc, getDoc } from "firebase/firestore";
import VueApexCharts from "vue-apexcharts";

import Table from "@/components/Table.vue";
import User from "@/models/user.js";

import Delete from "@/components/Delete.vue";
import { mask } from 'vue-the-mask'

import UserPayments from "@/components/UserPayments.vue";
import LicsenceManagerVue from '@/components/LicsenceManager.vue';

import moment from "moment";

import UserImage from '@/components/profile/UserImage.vue';

import Preferences from '@/components/training/AdminPreferences.vue';

import UsersExport from '@/components/export/UsersExport.vue';


import { logAuditEvent } from '@/error/audit.js';

export default {
  directives: { mask },
  components: {
    Table,
    Delete,
    UserPayments,
    "VueApexCharts": VueApexCharts,
    LicsenceManagerVue,
    UserImage,
    Preferences,
    UsersExport,
  },
  mounted() {
    if (this.$store.state.Auth.token.claims.type == "superuser" || this.$store.state.Auth.token.claims.type == "admin") {
      this.headers.push({ text: "Tipo", value: "type" });
      this.loading = true;
    }
  },
  data() {
    return {
      genders: [
        { text: "Femenino", value: "female" },
        { text: "Masculino", value: "male" },
        { text: "No binario", value: "nonBinary" },
        { text: "Prefiero no decirlo", value: "preferNotToSay" }
      ],
      requiredRules: [
        (v) => !!v || "Este campo es requerido",
      ],
      menuEndOfSubscription: false,
      editEmail: false,
      newEmail: null,

      editCivilId: false,
      newCivilId: null,
      disableReason: null,
      series: [],
      weekdaySeries: [],
      totalWorkouts: 0,

      chartOptions: {
        toolbar: {
          show: false,
        },
        theme: {
          mode: 'dark',
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 1,
          labels: {
            formatter: function (value) {
              return value == 0 ? 'No asistio' : 'Asistio';
            }
          }
        },

        xaxis: {
          type: "datetime",
        },
        title: {
          text: "Asistencias",
        },
      },
      selectedTab: 0,
      tabs: ["Cuenta", "Pagos", "Evaluaciones"],
      headers: [
        { text: 'Nombre', value: 'displayName' },
        { text: "ID", value: 'civilId' },
        //{ text: "Email", value: 'email' },
        { text: "Telefono", value: 'phoneNumber' },
        //{ text: "Vencimiento", value: "endOfSubscription" },
        { text: "Vencimiento", value: "expired" },

        { text: "Observaciones", value: "observation" },
        { text: "Lesiones", value: "injuries" },
        { text: "Plan", value: "plan" }
      ],
      loading: false,
      userTypes: [
        { text: "Socio", value: "usuario" }
      ],
      recordingTypes: ["download", "cloudstorage"],
      recordTypes: ['disabled', 'both', 'only agent'],
      companySelected: "",
      user: {},
      users: [],
      companies: [],
      buttonLoader: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido.",
        email: (value) => {
          const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
          return pattern.test(value) || 'Correo electrónico inválido.';
        },
      },
      dialogs: {
        delete: false,
        add: false,
        save: false,
        payment: false,
        evaluation: false,
        evaluationDate: false
      },
      errorPhone: false,
      menu: false,
      dates: [],
      evaluations: [], // This should be populated with evaluations from Firestore
      selectedEvaluationIndex: null,
      dialog: false,
      selectedEvaluation: {},
      evaluation: {},
      menuEval: false,
      disableDialog: false,
      enableDialog: false,
      viewBajas: false,
      evaluationDateObj: {
        menu: false,
        menuTime: false,
        date: null,
        time: null,
        doc_id: null,
      },




    };
  },

  created() {
    if (this.$store.state.Auth.token.claims.type == "superuser") {
      // this.userTypes.push( "admin", "entrenador", "paselibre", "gratis", "canje", "personalizado","representante","opengym",'online');
      this.userTypes.push({
        text: "Socio",
        value: "usuario",
      },
        {
          text: "Entrenador",
          value: "entrenador",
        },
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "Representante",
          value: "representante",
        },
        {
          text: "Paselibre",
          value: "paselibre",
        },
        {
          text: "Gratis",
          value: "gratis",
        },
        {
          text: "Canje",
          value: "canje",
        },
        {
          text: "Personalizado",
          value: "personalizado",
        },
        {
          text: "Opengym",
          value: "opengym",
        },
        {
          text: "Online",
          value: "online",

        })



    }
    if (this.$store.state.Auth.token.claims.type == "admin") {
      //this.userTypes.push( "paselibre", "gratis", "entrenador", "admin", "representante", "opengym", "online");

      this.userTypes.push({
        text: "Socio",
        value: "usuario",
      },
        {
          text: "Entrenador",
          value: "entrenador",
        },
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "Representante",
          value: "representante",
        },
        {
          text: "Paselibre",
          value: "paselibre",
        },
        {
          text: "Gratis",
          value: "gratis",
        },
        {
          text: "Canje",
          value: "canje",
        },
        {
          text: "Personalizado",
          value: "personalizado",
        },
        {
          text: "Opengym",
          value: "opengym",
        },
        {
          text: "Online",
          value: "online",
        }
      )
    }

    //sort usertypes
    this.userTypes.sort((a, b) => (a.text > b.text) ? 1 : -1)

    if (this.$store.state.Auth.token.claims.type == "superuser" || this.$store.state.Auth.token.claims.type == "admin") {
      this.getUsers();
    }

  },
  methods: {
    async sendMail() {

      const result = await User.sendMail("reminder", this.user.civilId);
      console.log(result)
    },



    async saveEmail() {
      try {
        this.loading = true;
        const user = this.user;
        const userInstance = new User(user);
        this.newEmail = this.newEmail.toLowerCase();
        let result = await User.updateEmail(userInstance, this.newEmail);
        let index = this.users.findIndex(x => x.id == this.user.id);
        this.users[index].email = this.newEmail;
        this.user.email = this.newEmail;
        this.newEmail = null;
        this.editEmail = false;
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "Email",
          text: "Email actualizado correctamente",
        });



      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;

      }
    },
    async saveCivilId() {
      try {

        //check if id exist locally and notify

        if (this.users.find(x => x.civilId == this.newCivilId)) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "ID/Documento",
            text: "Ya existe un usuario con este ID/Documento",
          });
          return;
        }



        //continue
        this.loading = true;
        const user = this.user;
        const userInstance = new User(user);
        let result = await User.updateCivilId(userInstance, this.newCivilId);




        //update local user with new civilId and update on the local array

        let index = this.users.findIndex(x => x.id == this.user.id);
        this.users[index].civilId = this.newCivilId;
        this.users[index].id = this.newCivilId;

        this.user.civilId = this.newCivilId;
        this.user.id = this.newCivilId;

        this.newCivilId = null;


        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "ID/Documento",
          text: "ID/Documento actualizado correctamente",
        });
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
        this.editCivilId = false;

      }
    },





    openDialog(index) {
      if (index != -1) {
        this.evaluation = { ...this.evaluations[index].result };
      }
      this.dialogs.evaluation = true;
      this.selectedEvaluationIndex = index;
    },
    closeDialog() {
      this.dialogs.evaluation = false;
      this.evaluation = {};
      this.selectedEvaluationIndex = null;
    },
    async submitEvaluation() {

      const evaluation = {
        ...this.evaluation,
        date: Timestamp.fromDate(moment().toDate()),
        evaluatedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email
      };

      try {
        this.loading = true;
        const db = getFirestore();
        delete evaluation['date']
        // update to the evaluations history collection inside result attribute

        let selectedEvaluationId = this.evaluations[this.selectedEvaluationIndex].doc_id;

        await updateDoc(doc(db, "evaluationsHistory", selectedEvaluationId), {
          result: evaluation,
          evaluatedBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email,
          evaluatedDate: Timestamp.fromDate(moment().toDate())
        });

        // update the local evaluations array
        const index = this.evaluations.findIndex(e => e.doc_id == selectedEvaluationId);
        this.evaluations[index].evaluatedBy = this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email;
        this.evaluations[index].result = evaluation;

        this.dialogs.evaluation = false;
        this.selectedEvaluation = null;
        // show success notification
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "Evaluación",
          text: "Evaluación completada",
        });




      } catch (e) {
        console.error("Error adding evaluation: ", e);
      } finally {
        this.loading = false;
      }
    },

    async fetchEvaluations(userid) {
      const db = getFirestore();
      const evaluationsRef = collection(db, `evaluationsHistory`);
      const q = query(evaluationsRef, where('id', '==', userid), orderBy('date', 'desc'));

      const querySnapshot = await getDocs(q);

      this.evaluations = querySnapshot.docs.map(doc => ({ ...doc.data(), doc_id: doc.id }));

      //get the user display name
      this.evaluations.forEach(async (evaluation, index) => {
        this.evaluations[index].displayName = this.user.displayName;
      });


    },
    formatDataForChart(workouts, startDate, endDate) {
      const data = {};
      const currentDate = moment(startDate);
      const stopDate = moment(endDate);
      let totalWorkouts = 0;
      // Add every date between startDate and endDate to the data object
      while (currentDate <= stopDate) {
        data[currentDate.format('YYYY-MM-DD')] = 0;
        currentDate.add(1, 'days');
      }

      // Count the workouts for each date
      workouts.forEach(workout => {
        workout.dates.forEach(date => {
          const dateOnly = date.substring(0, 10); // Get only the date part, not the time.
          if (data[dateOnly] !== undefined) {
            data[dateOnly]++;
            totalWorkouts++;
          }
        });
      });

      // Create series data for the chart
      const seriesData = Object.keys(data).map((date) => {
        return {
          x: new Date(date),
          y: data[date],
        };
      });

      this.series = [
        {
          name: "Asistencias",
          data: seriesData,
        },
      ];
      this.totalWorkouts = totalWorkouts;

      const weekdayCount = {
        'Domingo': 0,
        'Lunes': 0,
        'Martes': 0,
        'Miércoles': 0,
        'Jueves': 0,
        'Viernes': 0,
        'Sábado': 0
      };
      const weekdays = Object.keys(weekdayCount);

      workouts.forEach(workout => {
        workout.dates.forEach(date => {
          const dateObj = new Date(date);
          const dayName = weekdays[dateObj.getDay()];
          weekdayCount[dayName]++;
        });
      });

      const weekdayData = Object.entries(weekdayCount).map(([day, count]) => {
        return {
          x: day,
          y: count,
        };
      });

      this.weekdaySeries = [
        {
          name: "Assistances",
          data: weekdayData,
        },
      ];
    },

    async searchWorkouts() {
      const workouts = await this.getWorkoutsBetweenDates(
        this.dates[0],
        this.dates[1]);

      this.formatDataForChart(workouts, this.dates[0], this.dates[1]);
    },
    async getWorkoutsBetweenDates(startDate, endDate) {
      const db = getFirestore();
      const workouts = [];

      try {
        // Query subcollection workouts
        const workoutsRef = collection(db, `/users/${this.user.id}/workouts`);
        const formattedStartDate = new Date(startDate + " 00:00:00");
        const formattedEndDate = new Date(endDate + " 23:59:59");

        const q = query(
          workoutsRef,
          where("weekStart", ">=", formattedStartDate),
          where("weekStart", "<=", formattedEndDate),
          orderBy("weekStart")
        );

        const workoutQuerySnapshot = await getDocs(q);
        workoutQuerySnapshot.docs.forEach(doc => {
          const data = doc.data();
          // Filter dates within range and format them
          const dates = data.dates.filter(date =>
            moment(date).isBetween(startDate, endDate, null, '[]')
          ).map(date => ({
            date: date,
            dates: [date]  // Keep consistent structure with main collection
          }));
          workouts.push(...dates);
        });

        // Query main workouts collection
        const workoutsMainRef = collection(db, 'workouts');
        const startOfWeek = moment(startDate, 'YYYY-MM-DD').startOf('week').toDate();
        const endOfWeek = moment(endDate, 'YYYY-MM-DD').endOf('week').toDate();

        const q2 = query(workoutsMainRef,
          where("date", ">=", startOfWeek),
          where("date", "<", endOfWeek),
          where("user_id", "==", this.user.id)
        );

        const mainWorkoutDocs = await getDocs(q2);
        mainWorkoutDocs.forEach(doc => {
          const data = doc.data();
          workouts.push({
            date: moment(new Date(data.date.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss'),
            dates: [moment(new Date(data.date.seconds * 1000)).format('YYYY-MM-DD HH:mm:ss')]
          });
        });

        return workouts;

      } catch (error) {
        console.error("Error fetching workouts:", error);
        return [];
      }
    },
    validatePhoneInput() {
      if (
        this.user.phoneNumber == "" ||
        this.user.phoneNumber == null ||
        this.user.phoneNumber == undefined
      ) {
        return true;
      }
      let isValid = this.$refs.telephoneNumber.phoneObject.isValid;
      isValid ? (this.errorPhone = false) : (this.errorPhone = true);
      return isValid;
    },

    onInputPhone(val, obj) {
      let value = val;
      let isValid = obj.isValid;
      this.validatePhoneInput();
    },

    async getUsers() {
      try {
        this.loading = true;
        let users = await User.getAll();
        this.users = users;
        this.viewBajas = false;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: error,
        });
      } finally {
        this.loading = false;
      }
    },


    async searchUser(param) {
      try {
        if (this.$store.state.Auth.token.claims.type == 'superuser') {
          return false;
        }
        this.loading = true;


        let users = await User.searchUsers(param);
        this.users = users;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: error,
        });
      } finally {
        this.loading = false;
      }
    },

    /*  async getCompanies(){
       try {
         this.companies = await Company.getAll();
         if(this.$store.state.Auth.token.claims.type == 'admin'){
            this.companySelected = this.companies[0].id;
            await this.getUsers(this.companySelected);
         }
       } catch (error) {
         this.$notify({
           group: "feedback",
           duration: 5000,
           type: "error",
           title: "GET",
           text: error,
         });
       }
     }, */

    async remove() {
      this.buttonLoader = true;
      try {
        await User.remove(this.user, this.companySelected);
        this.users = this.users.filter((item) => {
          return item.id != this.user.id;
        });

        this.closeDelete();
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "BORRAR",
          text: "Usuario borrado correctamente",
        });
        this.dialogs.delete = false;
        this.dialogs.save = false;
        this.dialogs.add = false;
        this.selectedTab = 0;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "BORRAR",
          text: "Error al borrar usuario",
        });
      }
      this.buttonLoader = false;
    },

    async add() {

      let validation2 = this.$refs["add-form"].validate();
      let validation3 = this.validatePhoneInput();
      /*    
          if (['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(this.user.type)) {
            validation2 = this.$refs.payments.validateForm()
          }
     */
      if (!validation2 || !validation3) {
        this.buttonLoader = false;
        return false;
      }

      this.buttonLoader = true;

      this.user.phoneNumber = this.$refs.telephoneNumber.phoneObject.number.e164;
      try {
        const user = new User(this.user);
        let resultADD = await User.add(user);


        if (resultADD && resultADD.data && resultADD.data.error) {
          if (resultADD.data.error == 'user-already-exists') {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "Error",
              text: "ID o Documento ya registrado",
            });
            this.buttonLoader = false;
            return false;
          }

          // user-disabled

          if (resultADD.data.error == 'user-disabled') {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario dado de baja, no se puede agregar",
            });
            this.buttonLoader = false;
            return false;
          }


          if (resultADD.data.error == 'email-already-exists') {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Email ya registrado",
            });
            this.buttonLoader = false;
            return false;
          }
          debugger

          if (resultADD.data.error == 'phone-already-exists') {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Telefono ya registrado",
            });
            this.buttonLoader = false;
            return false;
          }


          if (resultADD.data.error == 'auth/phone-number-already-exists') {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Telefono ya registrado",
            });
            this.buttonLoader = false;
            return false;
          }


          // auth invalid password
          if (resultADD.data.error == 'auth/invalid-password') {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "ID invalido, debe tener al menos 6 digitos, y ser unico.",
            });
            this.buttonLoader = false;

          }

          if (resultADD.data.error == "auth/invalid-uid") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "ID invalido, debe tener al menos 6 digitos, y ser unico.",
            });
            this.buttonLoader = false;
            return false;
          }

          await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error al agregar usuario: ${resultADD.data.error} data for user: ${JSON.stringify(user)}`)

          return false;
        }



        user.id = resultADD.data.id;

        const copy = { ...user };
        copy.endOfSubscription = user.endOfSubscription;
        const newuser = new User(copy);
        this.users.push(newuser);
        this.closeAdd();
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "",
          text: this.$i18n.t('ADMIN.ADDSUCCESS'),
        });

      } catch (error) {

        await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error al agregar usuario: ${error.message} data for user: ${JSON.stringify(this.user)}`)

        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "",
          text: error.message,
        });
      }

      this.buttonLoader = false;
    },

    async save() {
      this.buttonLoader = true;
      if (this.$refs["add-form"].validate()) {
        try {
          this.user.phoneNumber = this.$refs.telephoneNumber.phoneObject.number.e164;
          const user = new User(this.user);
          await User.update(user);
          //this.getUsers();



          this.users = this.users.map((item) => {
            if (item.id == user.id) {
              item = user;
            }
            return item;
          });


          this.closeAdd();
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "Exito",
            text: "Usuario actualizado correctamente",
          });
        } catch (error) {

          await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `Error al actualizar usuario: ${error.message} data for user: ${JSON.stringify(this.user)}`)
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "Error",
            text: "Error al actualizar usuario",
          });
        }
      }
      this.buttonLoader = false;
    },

    closeDelete() {
      this.dialogs.delete = !this.dialogs.delete;
    },

    closeAdd() {
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.dialogs.delete = false;
      this.selectedTab = 0;
      this.$refs["add-form"].reset();
    },

    showDelete(user) {
      this.dialogs.delete = !this.dialogs.delete;
      this.user = user;
    },
    showAdd() {
      this.selectedTab = 0;
      this.series = [];
      this.weekdaySeries = [];
      this.totalWorkouts = 0;
      if (this.$refs["add-form"]) {
        this.$refs["add-form"].reset();
      }
      this.user = new User({ type: "usuario" });
      this.dialogs.add = !this.dialogs.add;
    },
    showAdd2(param) {
      this.selectedTab = 0;
      this.series = [];
      this.weekdaySeries = [];
      this.totalWorkouts = 0;
      if (this.$refs["add-form"]) {
        this.$refs["add-form"].reset();
      }
      this.user = new User({ type: "usuario" });


      this.dialogs.add = !this.dialogs.add;

    },
    showSave(user) {

      this.selectedTab = 0;
      this.series = [];
      this.weekdaySeries = [];
      this.totalWorkouts = 0;
      this.user = new User(user);
      this.fetchEvaluations(user.id);
      this.dialogs.add = true;
      this.dialogs.save = true;

    },
    paymentDone(paymentData) {
      if (paymentData) {
        let endOfSubscription = paymentData.endOfSubscription;

        let userIndex = this.users.findIndex((item) => item.id == paymentData.id);

        if (userIndex != -1) {
          // Clone the user object to modify
          const user = { ...this.users[userIndex] };

          if (endOfSubscription) {
            user.endOfSubscription = endOfSubscription;
            user.expired = moment(new Date(endOfSubscription.seconds * 1000)).diff(moment(), 'days');
          } else {
            user.expired = -1000000;
          }

          user.plan = parseInt(paymentData.amount);

          // Use Vue.set to ensure reactivity
          this.$set(this.users, userIndex, user);
        }
      }


      this.dialogs.payment = false;
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.dialogs.delete = false;
      this.selectedTab = 0;
    },
    async recordNewReEnabledUser() {
      try {
        this.loading = true;
        const db = getFirestore();
        const collectionRef = collection(db, "reEnabledUsers");
        const payload = {
          id: this.user.id,
          displayName: this.user.displayName,
          date: serverTimestamp(),
          reEnabledBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email
        };

        await addDoc(collectionRef, payload);
      } catch (error) {
        console.error("Error adding re-enabled user: ", error);
      } finally {
        this.loading = false;
      }
    },
    async recordNewDisabledUser() {
      try {
        this.loading = true;
        const db = getFirestore();
        const collectionRef = collection(db, "disabledUsers");
        const payload = {
          id: this.user.id,
          displayName: this.user.displayName,
          date: serverTimestamp(),
          reason: this.disableReason,
          disabledBy: this.$store.state.Auth.token.claims.name ? this.$store.state.Auth.token.claims.name : this.$store.state.Auth.token.claims.email
        };

        await addDoc(collectionRef, payload);

        await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} disabled`)

        this.disableReason = null;
      } catch (error) {
        console.error("Error adding disabled user: ", error);
      } finally {
        this.loading = false;
      }

    },
    async disableUser() {
      try {
        this.loading = true;

        let user = new User(this.user);
        user.disabled = true;

        if (user.endOfSubscription) {
          delete user.endOfSubscription;
          delete user.expired;
        }

        await User.update(user);
        this.user.disabled = true;
        await this.recordNewDisabledUser();

        this.users = this.users.filter((item) => {
          return item.id != user.id;
        });

        this.disableDialog = false;
        this.closeAdd();

        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "SAVE",
          text: "User updated successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "SAVE",
          text: "Error in save user",
        });
      } finally {
        this.loading = false;
      }
    },
    async loadDisabledUsers() {
      try {
        this.loading = true;
        let users = await User.getAllDisabled();
        this.users = users;
        this.viewBajas = true;
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "GET",
          text: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async enableUser() {

      try {
        this.loading = true;

        let user = new User(this.user);
        user.disabled = false;

        if (user.endOfSubscription) {
          delete user.endOfSubscription;
          delete user.expired;
        }
        await User.update(user);

        await this.recordNewReEnabledUser()


        await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} re-enabled`)

        this.users = this.users.filter((item) => {
          return item.id != user.id;
        });

        this.enableDialog = false;
        this.closeAdd();

        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "SAVE",
          text: "User updated successfully",
        });
      } catch (error) {
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "SAVE",
          text: "Error in save user",
        });
      } finally {
        this.loading = false;
      }

    },
    updateEndOfSubscription(newEndTimestamp) {


      let user = this.users.find((item) => {
        return item.id == this.user.id;
      });

      if (user) {
        user.endOfSubscription = newEndTimestamp;
        if (['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)) {

          user.expired = moment(new Date(user.endOfSubscription.seconds * 1000)).diff(moment(), 'days');


        }

      }

      this.dialogs.payment = false;
      this.dialogs.add = false;
      this.dialogs.save = false;
      this.dialogs.delete = false;
      this.selectedTab = 0;
      this.$forceUpdate();


    },
    async saveEvaluationDate() {
      try {
        //validate form 
        let validation = this.$refs["evaluationDateForm"].validate();
        if (!validation) {
          return false;
        }

        //save date in evaluationHistory
        let evaluationDateObject = {
          date: moment(this.evaluationDateObj.date + " " + this.evaluationDateObj.time + ":00", "YYYY-MM-DD HH:mm:ss").toDate(),
          id: this.user.id,
          evaluatedBy: null
        }
        this.loading = true;
        const db = getFirestore();

        if (this.evaluationDateObj.doc_id) {
          await updateDoc(doc(db, "evaluationsHistory", this.evaluationDateObj.doc_id), evaluationDateObject);
          this.evaluations = this.evaluations.map((item) => {
            if (item.doc_id == this.evaluationDateObj.doc_id) {
              item = evaluationDateObject;
            }
            return item;
          });
        } else {
          let result = await addDoc(collection(db, "evaluationsHistory"), evaluationDateObject);
          evaluationDateObject.doc_id = result.id;
          this.evaluations.push(evaluationDateObject)
        }

        this.evaluationDateObj = {
          menu: false,
          menuTime: false,
          date: null,
          time: null,
          doc_id: null
        };

        this.dialogs.evaluationDate = false;
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "Evaluación",
          text: "Fecha de evaluación guardada correctamente",
        });

        await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Evaluation date updated for user ${this.user.id} data: ${JSON.stringify(evaluationDateObject)}`)
        //add evaluation to the list

      } catch (error) {
        console.error("Error adding evaluation date: ", error);
      } finally {
        this.loading = false;
      }
    },
    editEvaluationDate(evaluation) {
      this.evaluationDateObj = {
        menu: false,
        menuTime: false,
        date: evaluation.date.seconds ? moment(evaluation.date.seconds * 1000).format("YYYY-MM-DD") : moment(evaluation.date).format("YYYY-MM-DD"),
        time: evaluation.date.seconds ? moment(evaluation.date.seconds * 1000).format("HH:mm") : moment(evaluation.date).format("HH:mm"),
        doc_id: evaluation.doc_id
      };
      this.dialogs.evaluationDate = true;
    },
    async deleteEvaluationDate() {
      try {
        this.loading = true;
        const db = getFirestore();
        await deleteDoc(doc(db, "evaluationsHistory", this.evaluationDateObj.doc_id));

        this.dialogs.evaluationDate = false;
        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "success",
          title: "Evaluación",
          text: "Fecha de evaluación eliminada correctamente",
        });

        await logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `Evaluation date deleted for user ${this.user.id} data: ${JSON.stringify(this.evaluationDateObj)}`)

        //remove from local array
        this.evaluations = this.evaluations.filter((item) => {
          return item.doc_id != this.evaluationDateObj.doc_id;
        });

        this.evaluationDateObj = {
          menu: false,
          menuTime: false,
          date: null,
          time: null,
          doc_id: null
        };


      } catch (error) {
        console.error("Error deleting evaluation date: ", error);
      } finally {
        this.loading = false;
      }
    },
    returnDateFormated(date) {
      if (date.seconds)
        return moment(date.seconds * 1000).format("DD/MM/YYYY HH:mm");

      return moment(date).format("DD/MM/YYYY HH:mm"); s
    },
  },
};
</script>
<style lang="scss" scoped>
.glass {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.7) !important;

  .theme--dark & {
    background: rgba(30, 30, 30, 0.7) !important;
  }
}

.v-tab {
  text-transform: none;
  letter-spacing: normal;
}

.rounded-lg {
  border-radius: 12px !important;
}


.v-date-picker-table .v-btn.v-btn--active {
  color: #000 !important;
}

.v-date-picker-table .v-btn.v-btn--active:before {
  background-color: #000 !important;
}
</style>

<style lang="scss">
.vti__dropdown-list {
  padding: 0px !important;
  max-width: 300px;
}

.vue-tel-input {
  border-radius: 12px !important;
  height: 52px !important;
  display: flex !important;
  border: 0px solid #bbb !important;
  text-align: left !important;
  background: #f0f0f0 !important;
}

.vue-tel-input:focus {
  outline: 0px !important;
  box-shadow: 0px 0px !important;
}

.vti__dropdown:focus {
  outline: 0px !important;
  box-shadow: 0px 0px !important;
}

.vti__dropdown:hover {
  background-color: #f3f3f3 !important;
  border-radius: 54px !important;
}

.vti__dropdown.open {
  background-color: #f3f3f3 !important;
  border-radius: 54px !important;
}

.theme--dark .vue-tel-input {
  background-color: #272727 !important;
}

.theme--dark .vti__dropdown-list {
  background-color: #272727 !important;
}

.theme--dark .vti__dropdown-item.highlighted {
  background-color: #303030 !important;
}

.theme--dark .vti__dropdown.open {
  background-color: #303030 !important;
}

.theme--dark .vti__input {
  color: white !important;
}
</style>